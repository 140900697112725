import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import { GreeterWidgetConfiguration, Layout, LayoutWidgetConfiguration } from './lib/layout-widget.model';

export const LAYOUT_WIDGET: WidgetInfo<LayoutWidgetConfiguration> = {
  type: WidgetType.LAYOUT,
  defaultConfiguration: async (opts: { layout: Layout; id: string }) => {
    return import('./lib/layouts.helper').then(module => module.createLayoutWidgetConfig(opts.layout, opts.id).config);
  },
  thumb: '',
  name: '',
  description: {},
  widgetComponent: () => import('./lib/layout-widget/layout-widget.component').then(module => module.LayoutWidgetComponent)
};

export const GREETER_WIDGET: WidgetInfo<GreeterWidgetConfiguration> = {
  type: WidgetType.GREETER,
  defaultConfiguration: () => null,
  thumb: '',
  name: '',
  description: {},
  widgetComponent: () => import('./lib/greeter-widget/greeter-widget.component').then(module => module.GreeterWidgetComponent)
};
