import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  appendTokensToUrl,
  AuthInterceptorConfig,
  getAuthToken,
  NamedAuthToken,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceAccessTokenProvider,
  ServiceTokenInterceptorConfig
} from '@celum/authentication';

import { QueryParamTokensProviderService } from './query-param-tokens-provider.service';
import { CONFIGURATION_SERVICE, ConfigurationService } from '../model/configuration.service';
import { EDIT_MODE } from '../util/portal-injection-tokens';

@Injectable()
export class AuthTokenService {
  constructor(
    private serviceAccessTokenProvider: ServiceAccessTokenProvider,
    @Inject(CONFIGURATION_SERVICE) private configurationService: ConfigurationService,
    @Inject(SERVICE_TOKEN_INTERCEPTOR_CONFIG) private interceptorConfig: ServiceTokenInterceptorConfig<AuthInterceptorConfig>,
    @Inject(EDIT_MODE) private editMode: boolean,
    @Optional() private tokenProviderService: QueryParamTokensProviderService
  ) {}

  public fetchAndAppendTokensToUrl(url: string): Observable<string> {
    if (!url) {
      return of(url);
    }

    return this.getTokens(url).pipe(map(tokens => appendTokensToUrl(url, tokens)));
  }

  public getTokens(url: string, tokenLeadTimeInMilliseconds?: number): Observable<NamedAuthToken[]> {
    if (!url) {
      console.debug('AuthTokenService: parameter url is missing, cannot determine which token to add.');
      return of(null);
    }

    const queryParamTokens = this.tokenProviderService?.getTokens();
    if (queryParamTokens) {
      return of([
        { name: 'token', token: queryParamTokens.b2cToken },
        { name: 'portalReadToken', token: queryParamTokens.portalReadToken }
      ]);
    }

    return this.configurationService.portalConfiguration$.pipe(
      take(1),
      switchMap(portalConfig => {
        if (portalConfig.protected || this.editMode) {
          return getAuthToken(url, this.interceptorConfig, this.serviceAccessTokenProvider, tokenLeadTimeInMilliseconds).pipe(
            map(token => (token ? [{ name: 'token', token: token }] : null))
          );
        } else {
          return of(undefined);
        }
      })
    );
  }
}
