import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import { FallbackWidgetConfiguration } from './lib/fallback-widget.component';

export const FALLBACK_WIDGET: WidgetInfo<FallbackWidgetConfiguration> = {
  type: WidgetType.FALLBACK,
  defaultConfiguration: () => ({}),
  thumb: '',
  name: '',
  description: {},
  widgetComponent: () => import('./lib/fallback-widget.component').then(module => module.FallbackWidgetComponent)
};
