import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { LogLevel } from '@azure/msal-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
  ApiConfiguration,
  AUTH_SERVICE_CONFIG,
  AuthInterceptorConfig,
  CelumAuthModule,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceTokenInterceptorConfig,
  TenantService
} from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';
import { CustomTranslateLoader } from '@celum/ng2base';
import { registerWidgets } from '@celum/portals/api';
import { PortalsProperties } from '@celum/portals/domain';
import { QueryParamTokensProviderService } from '@celum/portals/shared';
import { NoopTrackingService, provideApplicationInsights, TRACKING_SERVICE } from '@celum/shared/domain';

import { ROUTES } from './app.routes';
import { PortalsAuthGuard } from './portals-auth.guard';
import { PortalsAuthInterceptor } from './portals-auth.interceptor';
import { PortalsTokensInterceptor } from './portals-tokens.interceptor';

// make sure that basic information for available widgets are ready once the portal component is initialized
registerWidgets();

export const appConfig: ApplicationConfig = {
  providers: [
    // Important: HttpClientModule needs to be imported in order for interceptors to work
    importProvidersFrom(BrowserAnimationsModule, HttpClientModule),
    // translation stuff is needed here to set up translations handling, actual translation setup is done in the portal component
    provideHttpClient(),
    provideRouter(ROUTES),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translationFactory,
          deps: [HttpClient]
        }
      })
    ),
    importProvidersFrom(
      CelumAuthModule.forRoot({
        logLevel: LogLevel.Error
      })
    ),
    QueryParamTokensProviderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PortalsAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PortalsTokensInterceptor,
      multi: true
    },
    {
      provide: SERVICE_TOKEN_INTERCEPTOR_CONFIG,
      useFactory: serviceTokenInterceptorFactory,
      deps: [TenantService]
    },
    {
      provide: AUTH_SERVICE_CONFIG,
      useValue: { loadSaccUserAfterSignIn: false }
    },
    PortalsAuthGuard,
    {
      provide: TRACKING_SERVICE,
      useFactory: () => {
        const searchParams = new URLSearchParams(window.location.search);
        const isWorkReviewMode = searchParams.has('b2cToken') && searchParams.has('portalReadToken');

        return isWorkReviewMode ? new NoopTrackingService() : provideApplicationInsights().useFactory();
      }
    }
  ]
};

export function translationFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http);
}

function serviceTokenInterceptorFactory(tenantService: TenantService): ServiceTokenInterceptorConfig<AuthInterceptorConfig> {
  const getConfiguration = () => {
    const tenantIdForPortal = tenantService.getCurrentTenantId();

    const portalsAppServiceTokenRequest = {
      clientId: 'portals-app',
      orgId: tenantIdForPortal
    };

    const apiConfigurations: ApiConfiguration[] = [
      {
        apiUrls: [
          CelumPropertiesProvider.properties.appProperties.experience.apiUrl,
          PortalsProperties.properties.apiUrl,
          PortalsProperties.properties.binariesUrl,
          PortalsProperties.properties.azureContainerUrl
        ],
        serviceTokenRequestDto: portalsAppServiceTokenRequest,
        tenantIdHeader: tenantIdForPortal
      }
    ];

    return {
      apiConfigurations,
      passThrough403Error: true
    };
  };

  return {
    getInterceptorConfiguration: () => getConfiguration()
  };
}
