import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import type { ShareInfoConfiguration } from './lib/share-info-widget.model';

export const SHARE_WIDGET: WidgetInfo<ShareInfoConfiguration> = {
  type: WidgetType.SHARE,
  defaultConfiguration: () => null,
  thumb: '',
  name: '',
  description: {},
  widgetComponent: () => import('./lib/share-info-widget.component').then(module => module.ShareInfoWidgetComponent)
};
