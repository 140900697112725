import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import type { HeaderConfiguration } from './lib/header-widget.model';

export const HEADER_WIDGET: WidgetInfo<HeaderConfiguration> = {
  type: WidgetType.HEADER,
  defaultConfiguration: () => null,
  thumb: '',
  name: '',
  description: {},
  widgetComponent: () => import('./lib/header-widget.component').then(module => module.HeaderWidgetComponent)
};
