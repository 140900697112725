import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import type { ImageWidgetConfiguration } from './lib/image-widget.component';

export const IMAGE_WIDGET: WidgetInfo<ImageWidgetConfiguration> = {
  type: WidgetType.IMAGE,
  defaultConfiguration: () => ({}),
  thumb: 'widget-icon-image',
  name: 'EXPERIENCE.PORTAL_DESIGNER.WIDGET.IMAGE_WIDGET',
  description: {},
  widgetComponent: () => import('./lib/image-widget.component').then(module => module.ImageWidgetComponent)
};
