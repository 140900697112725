import { Type } from '@angular/core';

import { WidgetComponent } from '../components/widget.component';
import { WidgetConfigurationInfo } from '../model/widget-configuration-info';
import { WidgetInfo } from '../model/widget-info';

export type ResolvedWidgetInfo<T extends object> = WidgetInfo<T> & { resolvedWidgetComponent: Type<WidgetComponent<T>> };

export type FullWidgetInfo<C extends object> = WidgetInfo<C> & Partial<WidgetConfigurationInfo<C>>;

class WidgetRegistry {
  private static registry = new Map<string, FullWidgetInfo<any>>();

  public static registerWidget<C extends object>(widgetInfo: WidgetInfo<C>): void {
    if (this.registry.has(widgetInfo.type)) {
      console.warn(`WidgetRegistry: You must not register the same widget twice! ${widgetInfo.type} is already registered.`);
      return;
    }

    this.registry.set(widgetInfo.type, widgetInfo);
  }

  public static registerWidgetConfigInfo<C extends object>(widgetInfo: WidgetConfigurationInfo<C>): void {
    if (!this.registry.has(widgetInfo.type)) {
      throw new Error(`WidgetRegistry: You must first register the same widget basic information! ${widgetInfo.type} is not registered.`);
    }

    const basicInfo = this.registry.get(widgetInfo.type);

    this.registry.set(widgetInfo.type, { ...basicInfo, ...widgetInfo });
  }

  public static has(type: string): boolean {
    return this.registry.has(type);
  }

  public static get<C extends object>(type: string): FullWidgetInfo<C> {
    this.assertTypeIsAvailable(type);

    return this.registry.get(type);
  }

  private static assertTypeIsAvailable(type: string): void {
    if (!this.registry.has(type)) {
      throw new Error(`WidgetRegistry: There is nothing registered for type ${type}!`);
    }
  }
}

let reg = WidgetRegistry;

if (globalThis) {
  if (!(globalThis as any).CelumWidgetRegistry) {
    (globalThis as any).CelumWidgetRegistry = WidgetRegistry;
  } else {
    reg = (globalThis as any).CelumWidgetRegistry;
  }
}

export { reg as WidgetRegistry };
