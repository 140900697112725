import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import { VideoWidgetConfiguration } from './lib/video-widget.component';

export const VIDEO_WIDGET: WidgetInfo<VideoWidgetConfiguration> = {
  type: WidgetType.VIDEO,
  defaultConfiguration: (): VideoWidgetConfiguration => ({
    videoOptions: {
      controls: true,
      loop: false,
      autoplay: false
    }
  }),
  thumb: 'widget-icon-video',
  name: 'EXPERIENCE.PORTAL_DESIGNER.WIDGET.VIDEO_WIDGET',
  description: {},
  widgetComponent: () => import('./lib/video-widget.component').then(module => module.VideoWidgetComponent)
};
