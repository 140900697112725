import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

interface PortalTokens {
  b2cToken: string;
  portalReadToken: string;
}

@Injectable()
export class QueryParamTokensProviderService {
  private getQueryParamTokens: PortalTokens;

  public collectTokens(route: ActivatedRouteSnapshot) {
    if (!route.queryParams.b2cToken || !route.queryParams.portalReadToken) {
      return;
    }
    this.getQueryParamTokens = {
      b2cToken: route.queryParams.b2cToken,
      portalReadToken: route.queryParams.portalReadToken
    };
  }

  public getTokens(): PortalTokens {
    return this.getQueryParamTokens;
  }
}
