import { WidgetInfo, WidgetRegistry } from '@celum/portals/shared';

// somewhat ugly imports we should usually not have, but we want to be sure that we really only import the config and nothing more
// -> barrel imports already caused issues because of not correctly working tree shaking!
import { ASSET_GALLERY_WIDGET } from '../../../asset-gallery-widget/src/widget-info';
import { CONTENT_TEMPLATE_WIDGET } from '../../../content-template-widget/src/widget-info';
import { FALLBACK_WIDGET } from '../../../fallback-widget/src/widget-info';
import { HEADER_WIDGET } from '../../../header-widget/src/widget-info';
import { IMAGE_WIDGET } from '../../../image-widget/src/widget-info';
import { GREETER_WIDGET, LAYOUT_WIDGET } from '../../../layout-widget/src/widget-info';
import { SHARE_WIDGET } from '../../../share-info-widget/src/widget-info';
import { TEXT_WIDGET } from '../../../text-widget/src/widget-info';
import { VIDEO_WIDGET } from '../../../video-widget/src/widget-info';

/**
 * Each new widget would be required to be added to the WIDGET_LIST and provide a separate "load<widget>" method.
 * This is somewhat cumbersome, but a method to keep widgets separated from the portal app itself -> load widgets only on demand! We do not want to bind them
 * immediately with the app.
 * Later on, we might change that somewhat to a more dynamic mechanism (e.g. like nova widgets?). But for now it should be sufficient.
 */

export function registerWidgets(): void {
  (
    [
      HEADER_WIDGET,
      FALLBACK_WIDGET,
      ASSET_GALLERY_WIDGET,
      VIDEO_WIDGET,
      TEXT_WIDGET,
      IMAGE_WIDGET,
      LAYOUT_WIDGET,
      GREETER_WIDGET,
      SHARE_WIDGET,
      CONTENT_TEMPLATE_WIDGET
    ] as WidgetInfo<any>[]
  ).forEach(widget => WidgetRegistry.registerWidget(widget));
}
