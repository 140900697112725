import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, distinctUntilChanged, filter, Observable, pairwise, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

import { isTruthy } from '@celum/core';
import { PageConfiguration } from '@celum/portals/domain';

import { fallbackPage, portalPages } from '../util/page-configuration.util';

interface NavigationServiceState {
  activePageId?: string;
}

@Injectable()
export class PortalNavigationService extends ComponentStore<NavigationServiceState> {
  // eslint-disable-next-line eqeqeq
  public activePageId$ = this.select(state => state.activePageId).pipe(isTruthy());

  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);

  public init(pages: Observable<PageConfiguration[]>): void {
    this.setState({});
    this.handleNavigation(pages);
  }

  private handleNavigation(pages: Observable<PageConfiguration[]>): void {
    const pageSlugOnNavigationEnd$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getCurrentUrlParams(this.router.routerState).pageSlug),
      startWith(this.getCurrentUrlParams(this.router.routerState).pageSlug),
      distinctUntilChanged()
    );

    const portalPagesPairs$ = pages.pipe(startWith(null), pairwise());

    combineLatest([portalPagesPairs$, pageSlugOnNavigationEnd$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([[previousPortalPages, currentPortalPages], slug]) => {
        this.updateActivePageId(previousPortalPages, currentPortalPages, slug);
      });
  }

  private updateActivePageId(previousPortalPages: PageConfiguration[], currentPortalPages: PageConfiguration[], slug: string): void {
    // later on, we have to check here whether the page is accessible for the current user
    // and fallback to the first accessible page for the user (not simply 0)

    // no slug is given so redirect to first available page
    if (!slug) {
      const idOfFirstPage = portalPages(currentPortalPages)[0].id;
      this.patchState({ activePageId: idOfFirstPage });
      return;
    }

    // slug is available in pages so navigate to the page
    const activePage = currentPortalPages.find(page => page.slug === slug);
    if (activePage) {
      this.patchState({ activePageId: activePage.id });
      return;
    }

    // check if a page for the slug existed in the previous portal pages
    const pageFromPreviousConfig = previousPortalPages?.find(page => page.slug === slug);
    if (!pageFromPreviousConfig) {
      // slug was also not valid in previous config, we can't navigate anywhere meaningful, so use the fallback page
      const fallbackPageId = fallbackPage(currentPortalPages).id;
      this.patchState({ activePageId: fallbackPageId });
      return;
    }

    // check if the page from the previous config is still available in the current config
    const currentActivePage = currentPortalPages.find(page => page.id === pageFromPreviousConfig.id);
    if (currentActivePage) {
      // the slug changed, navigate to the new slug
      this.router.navigate(['../', currentActivePage.slug], { relativeTo: this.activatedRoute });
      return;
    }

    // the page was deleted, navigate to the first page
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * Collect all params from the current route, starting from root.
   * Reasoning: we cannot use ActivatedRoute, as this depends on the child route you are in. The ActivatedRoute provides access to information about a route
   * associated with a component that is loaded in an outlet. For the portal app use case, we have to provide this service on the "wrong" level, so it is not
   * able to get the param "pageSlug" from the current route.
   */
  private getCurrentUrlParams(routerState: RouterState): { [key: string]: string } {
    let params = {};
    let currentRoute = routerState.root;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      params = { ...params, ...currentRoute.snapshot.params };
    }

    return params;
  }
}
