import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { PortalsProperties } from '@celum/portals/domain';
import { QueryParamTokensProviderService } from '@celum/portals/shared';

@Injectable()
export class PortalsTokensInterceptor implements HttpInterceptor {
  private readonly apiUrl = PortalsProperties.properties.apiUrl;
  constructor(@Optional() private tokenProviderService: QueryParamTokensProviderService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add the tokens to requests to the portal API
    if (!req.url.startsWith(this.apiUrl)) {
      return next.handle(req);
    }

    const queryParamTokens = this.tokenProviderService?.getTokens();
    // If tokens are not available, continue without them
    if (!queryParamTokens) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        setHeaders: {
          Authorization: `Bearer ${queryParamTokens.b2cToken}`,
          'X-Portal-Read-Token': queryParamTokens.portalReadToken
        }
      })
    );
  }
}
