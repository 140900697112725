import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import { TextWidgetConfiguration } from './lib/text-widget.model';

export const TEXT_WIDGET: WidgetInfo<TextWidgetConfiguration> = {
  type: WidgetType.TEXT,
  defaultConfiguration: () => ({ html: {} }),
  thumb: 'widget-icon-text',
  name: 'EXPERIENCE.PORTAL_DESIGNER.WIDGET.TEXT_WIDGET.TITLE',
  description: {},
  widgetComponent: () => import('./lib/text-widget.component').then(module => module.TextWidgetComponent)
};
