import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import { ContentTemplateWidgetConfiguration } from './lib/content-template-widget.model';

export const CONTENT_TEMPLATE_WIDGET: WidgetInfo<ContentTemplateWidgetConfiguration> = {
  type: WidgetType.CONTENT_TEMPLATE,
  defaultConfiguration: () => ({}),
  thumb: 'widget-icon-content-template',
  name: 'EXPERIENCE.PORTAL_DESIGNER.WIDGET.CONTENT_TEMPLATE_WIDGET.NAME',
  description: {},
  widgetComponent: () => import('./lib/content-template-widget.component').then(module => module.ContentTemplateWidgetComponent)
};
